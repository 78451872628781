<template>
  <div class="mx-auto w-full max-w-screen-2xl grow">
    <div class="flex h-full flex-col space-y-8 md:flex-row md:space-y-0">
      <div
        class="h-48 w-full bg-[url('/img/welcome/welcome-sm.jpg')] bg-cover bg-[center_left_-120px] sm:bg-center md:h-full md:w-1/2 md:bg-[url('/img/welcome/welcome-full.jpg')]"
      ></div>
      <div
        class="text-grays-darkest mx-auto flex w-full max-w-xl flex-col justify-center space-y-6 px-4 md:w-1/2 md:px-8"
      >
        <h1 class="text-center text-xl font-semibold lg:text-2xl">
          Claim your eyewear benefit now
        </h1>
        <span class="text-center"
          >Enter your work email to see if you quality.</span
        >
        <label class="relative w-full">
          <span
            class="text-grays-darkest text-sm font-semibold"
            :class="{ 'text-red-dark': hasError }"
            >Email</span
          >
          <input
            ref="emailInput"
            v-model="email"
            tabindex="0"
            type="email"
            class="mt-1 block h-14 w-full rounded text-base focus:ring-0"
            :class="{ 'text-red-dark border-red-dark border': hasError }"
          />
          <p v-if="hasError" class="text-red-dark mt-2 flex space-x-1">
            <ZenniIconsIconError size="lg" class="-mt-1" />
            <span
              >Login unsuccessful. Please check your email and try again. If the
              issue continues, contact us.</span
            >
          </p>
        </label>
        <ZnButton
          tabindex="0"
          :disabled="!email"
          class="flex w-full justify-center font-semibold"
          :class="{ 'bg-grays-light text-white': !email }"
          @click="handleClick()"
          @keydown.enter="handleClick()"
        >
          <template v-if="status === 'pending' || status === 'success'">
            <svg
              class="h-6 w-6 animate-spin fill-current text-white"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M2,12A10.94,10.94,0,0,1,5,4.65c-.21-.19-.42-.36-.62-.55h0A11,11,0,0,0,12,23c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"
              />
            </svg>
          </template>
          <template v-else>
            <span>Get started</span>
          </template>
        </ZnButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toast } from '@/components/Toast'
import {
  definePageMeta,
  onMounted,
  ref,
  watch,
  useAsyncData,
  useRouter,
} from '#imports'
import { useGift } from '@/composables/api/useGift'
import { useGuestStore } from '~/store/guest'

definePageMeta({
  showSearch: false,
  showNav: false,
  hideCart: true,
  unauthorized: true,
})

onMounted(() => {
  emailInput.value.focus()
})

const guestStore = useGuestStore()
watch(() => guestStore.requestError, () => {
  if (guestStore.requestError) {
    toast.open({
        type: 'error',
        title: 'Error occured',
        content:
          'Failed to load gift information. If the issue persists, contact support',
      })
  }
}, { deep: true, immediate: true })

const emailInput = ref()
const email = ref<string>('')
const hasError = ref<boolean>(false)

const router = useRouter()
const { findGift } = useGift()

const { status, execute } = useAsyncData(
  'userGift',
  async () => {
    hasError.value = false
    const { data, error } = await findGift(email.value)

    if (data.value) {
      router.push({
        path: '/',
        query: {
          gift: data.value.id,
        },
      })
    } else if (error.value) {
      hasError.value = true
      throw new Error('Invalid email')
    }
  },
  { immediate: false },
)

const handleClick = () => {
  if (status.value !== 'pending' && status.value !== 'success') {
    execute()
  }
}
</script>
